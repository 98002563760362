<template>
  <div v-if="$store.state.dashboard.loading">
    <loader></loader>
  </div>
  <div id="dashboard" v-else>
    <div v-if="!dashboardErrors.templateConfiguration">
      <div
        id="progress-mobile"
        class="d-sm-block d-md-none d-lg-none"
        v-if="dashboardData && dashboardData.templateConfiguration && dashboardData.templateConfiguration.showTopHeader"
      >
        <dashboard-progress :progress="dashboardData.percentageComplete"></dashboard-progress>
      </div>
      <div
        class="jumbotron mb-3"
        :style="{ backgroundColor: this.style.subheaderBackgroundColor, color: this.style.subheaderTextColor }"
        v-if="
          !dashboardData.isOnboardingFinished &&
            dashboardData &&
            dashboardData.templateConfiguration &&
            dashboardData.templateConfiguration.showTopHeader
        "
      >
        <div class="container p-0">
          <div class="row">
            <div class="col-md-8 flex-column justify-content-center d-flex">
              <h6
                v-if="!user.isFirstTimeAccess"
                class="title introduction-title font-weight-bold"
                :style="{ color: this.style.welcomeTitleColor }"
              >
                {{ $t('dashboard.welcome', { name: user.firstName }) }}
              </h6>
              <h6
                v-else
                class="title introduction-title font-weight-bold"
                :style="{ color: this.style.welcomeTitleColor }"
              >
                {{ $t('dashboard.welcomeFirstTime', { name: user.firstName }) }}
              </h6>
              <component v-if="todos.length" v-bind:is="introductionText" v-bind="$props" />
              <div class="mt-2" id="notifications" v-if="shouldShowAllowNotifications">
                <component v-bind:is="allowNotificationsText" />
                <div id="notificationsBlocked" style="display: none">Notifications Blocked</div>
              </div>
            </div>
            <div class="col-md-2 d-flex flex-column justify-content-center">
              <hr class="d-block d-md-none d-lg-none my-4" style="margin-left: -5rem; margin-right: -5rem" />
              <div
                v-if="!dashboardData.templateConfiguration.disableCounter"
                id="counter"
                :class="dashboardData.isWorking == undefined && 'data-unavailable'"
              >
                <dashboard-counter
                  v-if="dashboardData.isWorking !== undefined"
                  :working="dashboardData.isWorking"
                  :days="Math.abs(dashboardData.calendarDays)"
                />
                <div v-else class="error-message">
                  <h6 class="title font-weight-bold">N/A</h6>
                  <el-tooltip
                    effect="light"
                    :content="$t('dashboard.noContentAvailable')"
                    placement="top"
                    popper-class="error-message-tooltip"
                  >
                    <i class="material-icons">info</i>
                  </el-tooltip>
                </div>
              </div>
            </div>
            <div class="col-md-2 d-none d-md-block">
              <div id="progress">
                <dashboard-progress :progress="dashboardData.percentageComplete"></dashboard-progress>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="jumbotron">
        <div class="container">
          <p>
            {{ 'dashboard.noContentAvailable' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div class="row" v-if="dashboardData.isOnboardingFinished">
      <div class="col-sm-12 col-md-12 no-margin-bottom">
        <div class="jumbotron">
          <h2 class="font-weight-bold">
            <span v-if="isOnboarding">{{ 'info.onboardingFinishedHeading' | translate }}</span
            ><span v-else>{{ 'info.onboardingFinishedHeadingOffboarding' | translate }}</span>
          </h2>
          <h3 style="line-height: 140%">
            <span v-if="isOnboarding"> {{ 'info.onboardingFinishedText' | translate }}</span>
            <span v-else> {{ 'info.onboardingFinishedTextOffboarding' | translate }}</span>
          </h3>
        </div>
      </div>
    </div>
    <div class="container" :class="{ 'mt-3': !dashboardData.templateConfiguration }">
      <div>
        <div v-if="$store.state.dashboard.loadingSections">
          <loader></loader>
        </div>
        <!-- Components -->
        <div v-else>
          <div class="row cards" id="dashboard-components" v-if="dashboardData.sections.length > 0">
            <template v-for="section in dashboardData.sections">
              <dashboard-module
                v-if="showModule(section)"
                :title="section.title"
                :titleColor="section.titleColor"
                :width="section.displayWidthType"
                :key="section.id"
                :no-side-padding="noSidePadding(section)"
                :body-class="section.bodyClass"
                :id="section.id"
                :flex="section.type === 9"
                :type="section.type"
              >
                <component :data="section" :is="section.component" />
              </dashboard-module>
            </template>
          </div>
          <div v-else-if="dashboardErrors.sections" class="container">
            <div class="row">
              <div class="card-body d-flex align-items-center justify-content-center">
                <i class="material-icons mr-3"> report_problem </i>
                <span>
                  {{ 'dashboard.noContentAvailable' | translate }}
                </span>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="row">
              <div class="card-body d-flex align-items-center justify-content-center">
                <span>
                  {{ 'dashboard.noContentToShow' | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div id="showEnableNotificationWithPhoneSettings">
      <div class="modal-content">
        <i @click="hideEnableNotificationWithPhoneSettings" class="material-icons close">close</i>
        <p>{{ this.$t('push.notifications.allow.settings.text') }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// Header
import dashboardCounter from '@app/components/dashboard/Counter';
import dashboardProgress from '@app/components/dashboard/Progress';

// Module Wrapper
import dashboardModule from '@app/components/ui/Module';

//Components
import dashboardCompanyPage from '@app/components/dashboard/CompanyPage';
import dashboardContacts from '@app/components/dashboard/Contacts';
import dashboardDidYouKnow from '@app/components/dashboard/DidYouKnow';
import dashboardForm from '@app/components/dashboard/Form';
import dashboardIntroduction from '@app/components/dashboard/Introduction';
import dashboardLearningPage from '@app/components/dashboard/LearningPage';
import dashboardPulseCheck from '@app/components/dashboard/PulseCheck';
import dashboardQuiz from '@app/components/dashboard/Quiz';
import dashboardVideo from '@app/components/dashboard/Video';
import tasks from '@app/mixins/tasks';
import planhatMixin from '@app/mixins/planhat';
import { getVapidDetails } from '@app/http/notification';
import { registerServiceWorkerNotifications } from '@app/notifications';

export default {
  mixins: [tasks, planhatMixin],
  components: {
    dashboardCompanyPage,
    dashboardContacts,
    dashboardCounter,
    dashboardDidYouKnow,
    dashboardForm,
    dashboardIntroduction,
    dashboardLearningPage,
    dashboardModule,
    dashboardProgress,
    dashboardPulseCheck,
    dashboardQuiz,
    dashboardVideo
  },
  created() {
    document.body.classList.add('is-on-dashboard');
    setTimeout(() => this.$store.dispatch('dashboard/getTodoData'), 200);
    if (!window.localStorage.getItem('publicKeyNotifications')) {
      getVapidDetails().then((e) => {
        e.json().then((result) => {
          window.localStorage.setItem('publicKeyNotifications', result.publicKey);
        });
      });
    }
  },
  mounted() {
    this.$store.dispatch('settings/getFeaturesWithConsent', this.user).then(() => {
      registerServiceWorkerNotifications();
    });
    // show legacyform reminder
    if (!window.localStorage.formShown && this.dashboardData) {
      if (!this.formsV2Enabled && this.dashboardData.formTodoLink && this.dashboardData.formTodoLink.length > 0) {
        const unfinishedTodos = this.dashboardData.formTodoLink.filter((todo) => !todo.isFinished);
        if (unfinishedTodos && unfinishedTodos.length > 0) {
          const { id, title } = unfinishedTodos.sort((a, b) =>
            a.title.toLowerCase() < b.title.toLowerCase() ? -1 : 0
          )[0];
          this.showFormReminder(title, `/form/${id}`);
        }
      }

      // show formv2 reminder
      if (this.formsV2Enabled && this.dashboardData.formsV2ToDo && this.dashboardData.formsV2ToDo.length > 0) {
        const unfinishedTodos = this.dashboardData.formsV2ToDo.filter((todo) => !todo.isFinished);

        if (unfinishedTodos && unfinishedTodos.length > 0) {
          const { formId, learningPageId, moduleId, formName } = unfinishedTodos.sort((a, b) =>
            a.formName.toLowerCase() < b.formName.toLowerCase() ? -1 : 0
          )[0];
          this.showFormReminder(formName, `/form/${moduleId}/${learningPageId}/${formId}`);
        }
      }
    }
  },
  computed: {
    ...mapGetters('auth', ['user', 'isAdmin']),
    ...mapGetters('settings', ['style', 'tenantData']),
    ...mapGetters('dashboard', ['dashboardErrors', 'isOnboarding']),
    allowNotificationsText() {
      const template = `<span id="allowNotifications">
      ${this.$t('push.notifications.allow', {
        allowNotificationLink: `<span>
            <a id="allowNotificationsLink" href="javascript:void(0);" class="font-weight-bold" style="text-decoration: underline;color:${
              this.style.tasksLinkColor
            }">${this.$t('push.notifications.allow.link.text')}</a>
            </span>`
      })}</span>`;
      return {
        template
      };
    },
    introductionText() {
      const translationIndex = this.todos.length == 1 ? 1 : 0;
      const template =
        '<span>' +
        this.$t(
          'dashboard.tasks',
          {
            taskCount:
              `<span id="dashboard-taskcount"><router-link :to="{name: 'tasks'}" class="font-weight-bold" style="text-decoration: underline;color:` +
              this.style.tasksLinkColor +
              '">' +
              this.$t('dashboard.taskCount', { count: this.todos.length }, translationIndex) +
              '</router-link></span>'
          },
          translationIndex
        ) +
        '</span>';
      const noDataAvailable = `<span>` + this.$t('dashboard.noContentAvailable') + `</span>`;
      return {
        template: !this.dashboardErrors.todoList ? template : noDataAvailable,
        props: this.$options.props
      };
    },
    shouldShowAllowNotifications() {
      // return true if you want to show this always for debugging purposes.
      return this.tenantData.enablePushNotifications && this.isInStandaloneMode() && this.isMobileDevice();
    }
  },
  methods: {
    isInStandaloneMode() {
      const isInWebAppiOS = window.navigator.standalone === true;
      const isInWebAppChrome = window.matchMedia('(display-mode: standalone)').matches;
      return isInWebAppiOS || isInWebAppChrome;
    },
    isMobileDevice() {
      let check = false;
      (function(a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        )
          check = true;
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    },
    hideEnableNotificationWithPhoneSettings() {
      document.querySelector('#showEnableNotificationWithPhoneSettings').style.display = 'none';
    },
    noSidePadding(section) {
      return (
        (section.type === 4 && section.displayWidthType > 1) ||
        section.type === 1 ||
        section.type === 6 ||
        section.type === 2 ||
        section.type === 13
      );
    },
    showModule(section) {
      return (
        (section.type !== 4 && section.type !== 5 && section.type !== 7 && section.type !== 8) || section.items.length
      );
    },
    showTodoReminder(title, path) {
      this.$alert(this.$t('form.newreminder', { title }), {
        showConfirmButton: true,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        customClass: 'small',
        confirmButtonText: this.$t('task.reminder.action.tasks'),
        callback: (action) => {
          window.localStorage.formShown = true;
          if (action === 'confirm') {
            this.$router.push('/tasks');
          }
        }
      });
    },
    showFormReminder(title, path) {
      this.$alert(this.$t('form.newreminder', { title }), {
        showConfirmButton: true,
        closeOnClickModal: true,
        closeOnPressEscape: true,
        customClass: 'small',
        confirmButtonText: this.$t('task.reminder.action.tasks'),
        callback: (action) => {
          window.localStorage.formShown = true;
          if (action === 'confirm') {
            this.$router.push('/tasks');
          }
        }
      });
    },
    trackPlanhat() {
      if (this.dashboardData.percentageComplete === 100) {
        if (!localStorage.getItem('percentageComplete')) {
          localStorage.setItem('percentageComplete', 100);
          this.track('tal_100_percent_complete');
        } else {
          return;
        }
      }
    }
  },
  beforeDestroy() {
    document.body.classList.remove('is-on-dashboard');
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_font-sizes.scss';

#dashboard-header {
  .col-md-4 {
    max-height: 220px;
  }
}

.cards {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;

  &.row {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }

  .card-module {
    @media (max-width: 768px) {
      padding-left: 0;
      padding-right: 0;
    }

    .card {
      flex-grow: 1;
    }
  }
}

#dashboard {
  background: white;
  padding-bottom: 1rem;
}

#dashboard .data-unavailable {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-message {
    position: relative;
    @media (max-width: 767px) {
      margin-bottom: 2rem;
    }

    h6 {
      font-size: 2rem;
    }

    i {
      max-width: none;
      position: absolute;
      top: -18px;
      right: -20px;
    }
  }
}

#dashboard .jumbotron {
  background: #fafafa;
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);
  border-radius: 0;

  @media (min-width: 768px) {
    padding: 1.5rem 5rem;
  }
  @media (min-width: 576px) and (max-width: 767px) {
    padding: 1.5rem 3rem 1rem;
  }
  @media (max-width: 575px) {
    padding: 1.5rem 0.5rem 1rem;
  }
}

#dashboard .jumbotron .introduction-title {
  font-size: var(--title-size);
}

#dashboard .jumbotron .container {
  max-width: 1240px;
}

@media (max-width: 767px) {
  .jumbotron {
    .introduction-title {
      margin-bottom: 1rem;
    }

    .introduction-text,
    .introduction-title {
      text-align: left;
    }
  }
  #dashboard .container {
    padding: 0 1rem;
    max-width: unset;

    .row {
      margin-left: 0;
      margin-right: 0;

      .col-sm-4,
      .col-md-6,
      .col-md-12 {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .panel {
      padding: 0px;
    }
  }
}

#dashboard .jumbotron .container {
  padding-left: 30px;
  padding-right: 30px;
  @media (max-width: 768px) {
    padding: 0;
  }
}

#dashboard-components {
  &.is-table-row {
    flex-flow: row wrap;

    .col-md-6,
    .col-md-12 {
      margin-top: 12px;
      margin-bottom: 12px;
    }

    @media (max-width: 768px) {
      .col-md-6,
      .col-md-12 {
        margin: 0;
      }

      .card-body {
        padding: 0 15px 0 15px !important;
      }
    }
  }
}

#showEnableNotificationWithPhoneSettings {
  display: none;
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);

  .modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    z-index: 100;
  }

  .close {
    text-align: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }
}
</style>
